import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "persiapan-awal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#persiapan-awal",
        "aria-label": "persiapan awal permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Persiapan Awal`}</h1>
    <p><img parentName="p" {...{
        "src": "https://docs.tokovoucher.net/banner.png",
        "alt": "alt text"
      }}></img></p>
    <p>{`Beberapa hal yang harus diperhatikan sebelum integrasi :`}</p>
    <ul>
      <li parentName="ul">{`Dapatkan `}<inlineCode parentName="li">{`Secret Key`}</inlineCode>{` & `}<inlineCode parentName="li">{`Member Code`}</inlineCode>{` di halaman `}<a parentName="li" {...{
          "href": "https://member.tokovoucher.net/pengaturan/secret-key"
        }}>{`Pengaturan Secret Key `}</a></li>
      <li parentName="ul">{`Silahkan set `}<inlineCode parentName="li">{`Content-Type`}</inlineCode>{` pada header menjadi `}<inlineCode parentName="li">{`application/json`}</inlineCode>{` jika menggunakan method POST`}</li>
      <li parentName="ul">{`Seluruh transaksi via API akan menggunakan method `}<inlineCode parentName="li">{`GET`}</inlineCode>{` & `}<inlineCode parentName="li">{`POST`}</inlineCode></li>
    </ul>
    <h1 {...{
      "id": "️-penting-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#%EF%B8%8F-penting-",
        "aria-label": "️ penting  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⚠️ Penting !!!`}</h1>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Semua HTTP Error`}</strong>{` harus diset sebagai transaksi `}<strong parentName="li">{`PENDING`}</strong>{` `}<br /></li>
      <li parentName="ul"><strong parentName="li">{`NETWORK TIMEOUT`}</strong>{` harus diset sebagai transaksi `}<strong parentName="li">{`PENDING`}</strong>{` `}<br /></li>
      <li parentName="ul">{`Pastikan menunggu callback final dari tokovoucher untuk mendapatkan status transaksi `}<br /></li>
      <li parentName="ul">{`Atau bisa mengecek status transaksi secara berkala setiap 10 menit untuk mendapatkan status transaksi `}<br /></li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Silahkan Whitelist IP Kami : 188.166.243.56
Semua request pada API menggunakan URL : `}<br />{`
`}<inlineCode parentName="p">{`https://api.tokovoucher.net/`}</inlineCode>{` untuk jalur API method POST dan GET
`}<inlineCode parentName="p">{`http://trx-ip.tokovoucher.net/`}</inlineCode>{` untuk jalur IP`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      